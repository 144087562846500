import {motion} from 'framer-motion'
import styled, {css} from 'styled-components'

export const Container = styled(motion.div)`
  ${({theme}) => css`
    max-width: 62.4rem;
    display: grid;
    gap: 2.4rem;
    position: relative;
    cursor: pointer;

    @media (max-width: ${theme.breakpoints.large}) {
      max-width: 100%;
      flex-direction: column;
      gap: 2.4rem;
    }
  `}
`

export const ImageWrapper = styled.div`
  ${({theme}) => css`
    transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
    overflow: hidden;

    img {
      border-radius: 1rem;
      object-fit: cover;
      width: 100%;
      height: 41.6rem;
    }

    @media (max-width: ${theme.breakpoints.large}) {
      max-width: 100%;
    }

    @media (max-width: ${theme.breakpoints.normal}) {
      height: 100%;

      img {
        height: auto;
      }
    }

    @media (max-width: ${theme.breakpoints.small}) {
      box-shadow: none;
    }
  `}
`

export const ArticleInfo = styled.div`
  width: 100%;

  p {
    margin-top: 1.6rem;
  }
`

export const NewWrapper = styled.div`
  margin-top: 0.8rem;
`

export const ReadTimeWrapper = styled.div`
  ${({theme}) => css`
    display: flex;
    align-items: center;
    gap: 0.8rem;
    margin-top: 3.2rem;

    svg {
      color: ${theme.colors.text};
    }

    p {
      margin-top: 0;
    }

    @media (max-width: ${theme.breakpoints.medium}) {
      margin-top: 1.6rem;
    }
  `}
`

export const ItemHover = styled(motion.div)`
  ${({theme}) => css`
    position: absolute;
    top: -2.4rem;
    left: -2.4rem;
    bottom: -2.4rem;
    right: -2.4rem;
    background: ${theme.colors.headerHoverColor};
    border-radius: 1.6rem;
    z-index: -1;

    @media (max-width: ${theme.breakpoints.small}) {
      display: none;
    }
  `}
`
